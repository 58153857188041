






























import { Component, Prop, Vue } from "vue-property-decorator";
import { Credentials } from "../models";

@Component({})
export default class LoginForm extends Vue {
  user: string = "";
  password: string = "";

  get validated(): boolean {
    return this.user !== "" && this.password !== "";
  }

  onClick() {
    const credentials: Credentials = {
      identifier: this.user,
      password: this.password,
    };

    this.$emit("submitted", credentials);
  }
}
