


















import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Credentials } from "@/models";
import LoginForm from "@/components/LoginForm.vue";

import { ApiRequest } from "@/store/types";

import { mixins } from "vue-class-component";
import ContentComponent from "@/components/ContentComponent";
import AuthorizationComponent from "@/components/AuthorizationComponent";

@Component({
  components: {
    LoginForm,
  },
})
export default class MentorLogin extends mixins(
  ContentComponent,
  AuthorizationComponent,
) {
  constructor() {
    super();
    this.componentName = "mentor login";
  }

  submitted(credentials: Credentials) {
    this.login(credentials);
  }
}
