import { Vue, Component } from "vue-property-decorator";

import { namespace } from "vuex-class";
const authorizationModule = namespace("authorization");

import { ApiRequest } from "@/store/types";
import {
  AuthorizationActionTypes,
  AuthorizationGetterTypes,
} from "@/store/authorization/types";
import { User, Credentials } from "models";

@Component
export default class ContentComponent extends Vue {
  @authorizationModule.Action(AuthorizationActionTypes.Login)
  login!: (credentials: Credentials) => void;

  @authorizationModule.Action(AuthorizationActionTypes.LoadUser)
  loadUser!: () => void;

  @authorizationModule.Getter(AuthorizationGetterTypes.CurrentUser)
  currentUser!: ApiRequest<User>;

  mounted() {
    if (!this.currentUser || this.currentUser.empty) {
      this.loadUser();
    }
  }
}
